.authentication__header {
    color: white;
    text-align: center;
}

.authentication {
    width: 90%;
    max-width: 25rem;
    margin: 7rem auto;
    text-align: center;
}

.authentication form {
    margin-bottom: 1rem;
}

.incorrect {
    color: red;
}

.pw-label {
    width: 100%;
    display: block;
    text-align: start;
}

.pw-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 2rem;
}