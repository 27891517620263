.top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #666666;
    position: relative;
    width: 100vw;
    height: 5rem;
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
}

.close>img {
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
    -moz-filter: opacity(50%);
    cursor: pointer;
}

.close>img:hover {
    filter: opacity(100%) invert(12%) sepia(94%) saturate(5971%) hue-rotate(8deg) brightness(79%) contrast(117%);
    -webkit-filter: opacity(100%) invert(12%) sepia(94%) saturate(5971%) hue-rotate(8deg) brightness(79%) contrast(117%);
    -moz-filter: opacity(100%) invert(12%) sepia(94%) saturate(5971%) hue-rotate(8deg) brightness(79%) contrast(117%);
    cursor: pointer;
}

.content-container {
    display: flex;
    flex-wrap: wrap;
}

.map-container {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    flex-grow: 1;
    z-index: -1 !important;
}

.filter-container {
    margin: 1rem;
}

.modal-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.modal-card {
    position: absolute;
    top: 25%;
    z-index: 101;
}

.page-title {
    padding: 1rem;
    font-size: 3rem;
    font-family: "Futura CE Book", Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
    white-space: nowrap;
}

.red {
    color: #cc0000;
}

.snow {
    color: white;
}

.storefront-list {
    padding: 1rem;
}

.map-container.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
    z-index: 1;
}

.leaflet-tile-pane {
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
    -moz-filter: opacity(50%);
}

.p-button.p-component,
.p-button {
    background-color: #cc0000;
    border: #666666;
}

.p-button:hover.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background-color: #666666;
}

button.p-button.p-component.filter-button {
    background-color: #cc0000;
    border: #666666;
    display: flex;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-header>img {
    filter: invert(100%);
}

.filter-content {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    /* overflow: auto;
    height: 50vh; */
}

.filter-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
}

.filter-section__label {
    font-size: 1.25rem;
}

.filter-section__content {
    display: flex;
    flex-wrap: wrap;
}

.filter-control {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.block {
    display: block;
}

.p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    width: 150px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    font-size: 0.75rem;
}

.storefront-content {
    /* margin: auto; */
    width: 90%;
}

.slick-arrow {
    filter: invert(100%);
}

.slick-slide img {
    object-fit: contain;
    width: 100%;
}

.storefront-fields {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.storefront-field {
    padding: 1rem;
    width: calc(640px / 3 - 3rem);
}

#description {
    width: 100%;
    max-height: calc(30vh - 7rem);
    min-height: 5rem;
    overflow-y: auto;
    padding: 0;
}

.storefront-field__label {
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.storefront-field__data {
    margin: 0;
    font-size: 1.5rem;
}

.storefront-list__button {
    margin: 0.5rem;
    background-color: #cc0000;
    border: #666666;
    font-size: 1.25rem;
}

.filter-content-sidebar {
    display: none;
}

.map-icon {
    filter: invert(10%) sepia(93%) saturate(5228%) hue-rotate(360deg) brightness(96%) contrast(115%);
}


/* #campaign-dropdown {
    position: absolute;
    right: 1rem;
} */


/* .storefront-title {} */

.storefront-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-image {
    height: 500px;
}

.fullscreen-image {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: zoom-out;
}

.fullscreen-image>img {
    max-height: 100vh;
    max-width: 100vw;
}

.magnify {
    text-align: center;
    cursor: zoom-in;
}

@media only screen and (min-width: 1200px) {
    .root-container {
        display: flex;
    }
    .filter-content-sidebar {
        padding: 1rem;
        display: block;
        background-color: #cc0000;
        height: 100vh;
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 5rem;
    }
    .filter-container {
        display: none;
    }
    .filter-section__label {
        font-size: 1rem;
    }
    .storefront-field__data {
        font-size: 1.25rem;
    }
    .storefront-list__button {
        font-size: 1rem;
    }
    h2 {
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 767px) {
    .filter-section {
        width: 240px;
    }
    .filter-section__content {
        max-width: 250px;
    }
    .filter-control {
        max-width: 250px;
    }
    .storefront-title,
    .p-dropdown-label,
    .storefront-field__data,
    .p-dropdown-item.p-highlight {
        font-size: 1rem;
    }
    .storefront-field__label {
        font-size: 0.75rem;
    }
    .storefront-field {
        width: calc(545px / 3 - 3rem);
        padding: 1rem 1rem 0 0;
    }
    .slider-image {
        height: 300px;
    }
}

@media screen and (min-height: 600px) {
    .close {
        position: absolute;
        bottom: 1rem;
        right: 2rem;
    }
}