.modal {
    z-index: 100;
    position: fixed;
    top: 2.5vh;
    left: 10vw;
    width: 80vw;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    /* max-height: 80vh; */
}

.modal__header {
    /* width: 100%; */
    padding: 1rem 0.5rem;
    background: #cc0000;
    color: white;
    border-radius: 8px 8px 0 0;
}

.modal__header h2 {
    margin: 0.5rem;
}

.modal__content {
    padding: 1rem 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 80vh;
    display: flex;
    justify-content: center;
}

.modal__footer {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}

@media only screen and (max-width: 400px) {
    .modal {
        width: 22rem;
        left: calc(50% - 11rem);
        height: 40rem;
        top: calc(50% - 20rem);
    }
    .modal__content {
        padding: 1rem 0.5rem;
        overflow-y: auto;
        height: 76vh;
    }
    .modal__header {
        padding: 0.5rem;
    }
}

@media only screen and (max-width: 400px) and (min-height: 768px) {
    .modal__content {
        padding: 1rem 0.5rem;
        overflow-y: auto;
        height: 60vh;
    }
}

@media only screen and (max-height: 400px) {
    .modal__header {
        padding: 0.5rem;
    }
}

.modal-enter {
    transform: translateY(-10rem);
    opacity: 0;
}

.modal-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
}

.modal-exit {
    transform: translateY(0);
    opacity: 1;
}

.modal-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 200ms;
}